import { watch } from 'vue';
import { useCartStore } from '@/shared/pinia/modules/cart.js';
import { useWebviewStore } from '@/shared/pinia/modules/webview.js';
import { lock, unlock } from 'tua-body-scroll-lock';

const miniCartOpenClassName = 'is-open';
const cartLoadingClassName = 'is-loading';
const webviewFulfilledClassName = 'is-fulfilled';

const miniCartLinesWrapperSelector = '.js-mini-cart-lines-wrapper'; // cart lines wrapper for mini cart

const cartWrapperSelector = '.js-cart-wrapper'; // whole main cart wrapper
const webviewMiniCartWrapperSelector = '.js-wv-mini-cart-wrapper'; // whole webview cart wrapper
const miniCartWrapperSelector = '.js-mini-cart-wrapper'; // whole minicart wrapper

const miniCartIconSelector = '.js-mini-cart-icon'; // minicart icon
const webviewCartCountSelector = '.js-wv-cart-count'; // icon cart count in webview
const miniCartModalSelector = '.js-mini-cart-modal'; // mini cart modal
const cartLineInputSelector = '.js-cart-line-input'; // elements of quantity selector (buttons and input)
const miniCartCloseSelector = '.js-mini-cart-close';
const cartLineErrorSelector = '.js-cart-line-error';
const cartScrollContainerSelector = '.js-cart-scroll-container';

const isMiniCart = !!document.querySelector(miniCartWrapperSelector);

/**
 * Cart related functions
 */

// This function is used to recalibrate tua-body-scroll-lock-plugin after the cart has been updated (deleted a product or quantity change).
const cartScrollLock = () => {
  const cartScrollContainer = document.querySelector(
    cartScrollContainerSelector
  );
  // Call unlock to handle cases where the cart is already open when updating the cart content
  unlock(cartScrollContainer);
  lock(cartScrollContainer);
};
// header cart modal toggle
const setMiniCartListeners = () => {
  const cartStore = useCartStore();
  const cartScrollContainer = document.querySelector(
    cartScrollContainerSelector
  );
  document
    .querySelector(miniCartIconSelector)
    ?.addEventListener('click', () => {
      document.querySelector('.js-header-search-clear')?.click();
      cartStore.miniCartOpen = true;
      cartScrollLock();
    });

  document.querySelectorAll(miniCartCloseSelector).forEach((el) => {
    el.addEventListener('click', () => {
      cartStore.miniCartOpen = false;
      unlock(cartScrollContainer);
    });
  });
};

// cart line item updates
const initiateCartEventListener = () => {
  const cartStore = useCartStore();

  document.getElementsByName('updates[]').forEach((el, idx) => {
    const lineNumber = idx + 1; // Shopify indexing is 1-based
    el.addEventListener('change', () => {
      const quantity = Number.parseInt(el.value);
      if (!Number.isNaN(quantity)) {
        cartStore.cartChangeQty(lineNumber, quantity);
      }
    });
  });
};

export const setCart = () => {
  const cartStore = useCartStore();

  // Expose functions to window
  window.cart = {
    setMiniCartListeners,
    initiateCartEventListener,
    cartChangeQty: cartStore.cartChangeQty
  };

  // Initiate cart functions
  window.cart.initiateCartEventListener();
  window.cart.setMiniCartListeners();

  watch(
    () => cartStore.miniCartOpen,
    (open) => {
      document
        .querySelector(miniCartModalSelector)
        ?.classList.toggle(miniCartOpenClassName, open);
    }
  );

  watch(
    () => cartStore.loadingCart,
    (loading) => {
      if (!loading) {
        // we get fresh html when the cart updates, so we only want to SET the loading state. Not remove it
        return;
      }

      document
        .querySelectorAll(`${cartWrapperSelector}, ${miniCartModalSelector}`)
        .forEach((el) => {
          el.classList.add(cartLoadingClassName);
        });

      document.querySelectorAll(cartLineInputSelector).forEach((el) => {
        el.disabled = true;
      });
    }
  );

  watch(
    () => cartStore.cart,
    async (cart) => {
      const webviewStore = useWebviewStore();

      const isCartPage = !isMiniCart;
      const isWebview = webviewStore.isWebview;

      if (isWebview && isMiniCart) {
        const cartCount = cart?.item_count ?? 0;
        const webviewHeaderCart = document.querySelectorAll(
          webviewMiniCartWrapperSelector
        );

        if (webviewHeaderCart) {
          const fulfilled = cartCount > 0;

          webviewHeaderCart.forEach((el) => {
            el.classList.toggle(webviewFulfilledClassName, fulfilled);
          });

          if (fulfilled) {
            document
              .querySelectorAll(webviewCartCountSelector)
              .forEach((el) => {
                el.textContent = cartCount; // update cart counter in header
              });
          }
        }
      } else {
        // html needs to be replaced
        const cartHtmlResponse = await fetch(
          `${window.Shopify.routes.root}cart?view=${
            isMiniCart ? 'mini-async' : 'async'
          }`
        );
        const cartHtml = await cartHtmlResponse.text();

        if (isCartPage) {
          document.querySelector('form[action="/cart"]').outerHTML = cartHtml;
        } else {
          const headerCartHtmlResponse = await fetch(
            `${window.Shopify.routes.root}cart?view=header-async`
          );
          const headerCartHtml = await headerCartHtmlResponse.text();
          document.querySelector('#header-cart').outerHTML = headerCartHtml;

          const cartElement = document.querySelector(miniCartWrapperSelector);

          const currentScrollPosition = cartElement.querySelector(
            miniCartLinesWrapperSelector
          )?.scrollTop;

          cartElement.outerHTML = cartHtml;
          window.cart.setMiniCartListeners(); // re-initiate header cart modal

          if (cartStore.miniCartOpen) {
            document
              .querySelector(miniCartModalSelector)
              ?.classList.add(miniCartOpenClassName);

            if (currentScrollPosition) {
              // put scroll restore at at the top of the call stack / let other stuff finish first
              setTimeout(() => {
                document
                  .querySelector(miniCartWrapperSelector)
                  .querySelector(miniCartLinesWrapperSelector).scrollTop =
                  currentScrollPosition;
              }, 0);
            }

            cartScrollLock();
          }
        }

        // display error messages if any
        if (cartStore.errors.length) {
          cartStore.errors.forEach((error) => {
            document
              .querySelectorAll(
                `[data-cart-line-number="${error.lineNumber}"] ${cartLineErrorSelector}`
              )
              .forEach((element) => {
                element.classList.remove('hidden');

                const messageEl = element?.querySelector(
                  `${cartLineErrorSelector}-msg`
                );

                if (messageEl) {
                  messageEl.textContent = error.message;
                }
              });
          });

          cartStore.clearErrors();
        }

        window.cart.initiateCartEventListener(); // re-initiate cart event listener
      }

      cartStore.loadingCart = false;
    }
  );
};
